
const LoadingPage = () => {

    return (
        <div className="row" style={{marginTop: "20%"}}>
            <div className="col-12 text-center">                
                <i className="bi bi-diagram-3-fill app-page-loading-spinner"></i>
                <h2>TIB Terminology Service ...</h2>
            </div>
        </div>
    );


}

export default LoadingPage;