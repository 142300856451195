export function renderHelpPage(){
    let content = [
        <div>
            <h4>HELP</h4>
            <p>For feedback, enquiries or suggestion about TIB Terminology Service:
                <b><a className="btn-secondary btn btn-sm ml-1 pt-0 pb-0 pl-1 pr-1" href={process.env.REACT_APP_PROJECT_SUB_PATH + "/contact"}> 
                Contact us</a>
                </b>
            </p>
            <p>To request a new terminology: 
                <a className="btn-secondary btn btn-sm pt-0 pb-0 pl-1 pr-1" href={process.env.REACT_APP_PROJECT_SUB_PATH + "/ontologysuggestion"}>
                    Ontology suggestion
                    </a>
                (requires sign-in)
            </p>
            <p>General information about chemistry ontologies can be found in the <a className = "ahome" target="_blank" rel="noopener noreferrer" href="https://knowledgebase.nfdi4chem.de/knowledge_base/docs/ontology/">chapter on ontologies</a> in our <a className = "ahome" target="_blank" rel="noopener noreferrer" href="https://knowledgebase.nfdi4chem.de/knowledge_base/">Knowledge Base</a>.</p>
            
            <p>You may also want to watch the recordings of our annual Ontologies4Chem workshop on YouTube:
                <ul>
                    <li><a className = "ahome" target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/playlist?list=PLlTKDYkC1Ls8wiHU-DzN1KwapxRpLkfVa">1st Ontologies4Chem Workshop in September 2022</a></li>
                    <li><a className = "ahome" target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/playlist?list=PLlTKDYkC1Ls_Vvym6FY1hdDzA1VcPGu9i">2nd Ontologies4Chem Workshop in October 2023</a></li>
                </ul> 
            </p>
            <p>For additional information or help on research data management, ontologies, data repositories or electronic lab notebooks (ELN) for chemistry visit our <a className = "ahome" target="_blank" rel="noopener noreferrer" href="https://knowledgebase.nfdi4chem.de/knowledge_base/">NFDI4Chem Knowledge Base</a> or our <a className = "ahome" target="_blank" rel="noopener noreferrer" href="https://nfdi4chem.de/">NFDI4Chem Website</a>.</p>
            
            <p>For announcements relating to our NFDI4Chem project such as new services or events, please sign up to our <a className = "ahome" target="_blank" rel="noopener noreferrer" href="https://lists.nfdi.de/postorius/lists/nfdi4chem-announce.lists.nfdi.de/">NFDI4Chem newsletter</a>.</p>
        </div>
    ];

    return content;
}