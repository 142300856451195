import React from "react";



export const NotFoundErrorPage = () => {
    return (
        <div className="row">
            <div className="col-sm-12">
                <h5>404. Object not Found.</h5>                
            </div>
        </div>
    );
}